import React from 'react'

import SubHero from '../sub-hero'
import './style/abuse.scss'

import AbuseImage from './../../images/abuse.jpg'

const Abuse = () => (
  <div className='abuse'>
    <SubHero
      title={'Report Abuse'}
      subTitle={'Abuse & Copyright Violation Reporting'}
      backgroundImage={AbuseImage}
      buttonVisibility={'hide-button'} />
    <div className='container'>
      <div className='row'>
        <div className='col-sm-12'>
          <p>WebsiteBuilder.com has systems in place to prevent the platform from being used in a manner that violates terms and conditions. We strive to make the web  a
          better place and will not allow websites breaching copyright, attempting to take personal details, hosting unsavory content, or activity that violates the
          policies of ICANN or appropriate registry. We investigate and record all reports of abuse submitted to us and where appropriate, may take action if a violation
          of any of our terms of service is confirmed.</p>

          <p>We may request additional information from you about your report. However, please note we may not be able to provide you with specific updates in response to
          your specific report due to privacy reasons.</p>

          <p>We aim to have sites deemed to be breaching our terms of service removed within 24 hours of reporting.</p>
          <p>If you feel you have discovered a violation, please email us at <a href='mailto:abuse@WebsiteBuilder.com'>abuse@WebsiteBuilder.com</a>.</p>
        </div>
      </div>
    </div>
  </div>
)
export default Abuse
