import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Abuse from '../components/abuse'

const IndexPage = () => (
  <Layout>
    <SEO
      title='Home'
      keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
      pageType={'company_info'}
      flowType={'none'} />
    <Abuse />
  </Layout>
)

export default IndexPage
